import React from "react";
import styled from "styled-components";

const H1Wrapper = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const H1 = ({ children }) => <H1Wrapper>{children}</H1Wrapper>;

export default H1;
