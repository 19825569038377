import React from "react";
import { Link, graphql } from "gatsby";
import Container from "../components/Container";
import MetaTags from "../components/MetaTags";
import H1 from "../components/H1";
import styled from "styled-components";

const PostsWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;

  section {
    h3 {
      margin-bottom: 0.5rem;
    }

    > div {
      margin-bottom: 2rem;
    }

    .post-meta {
      font-size: 1.2rem;
      margin: 0 0 1rem;
    }

    a {
      display: inline-block;
      margin-top: 1rem;
    }
  }
`;

const Posts = function ({ data, pageContext, path }) {
  return (
    <>
      <MetaTags title="Posts" />
      <PostsWrapper>
        <Container>
          <section>
            <H1>Posts</H1>
            {data.allMdx &&
              data.allMdx.edges.map(function ({ node: post }) {
                return (
                  <div key={post.id}>
                    <h3>
                      <Link to={`/posts${post.fields.slug}`}>
                        {post.frontmatter.title}
                      </Link>
                    </h3>
                    <div className="post-meta">
                      <time>{post.frontmatter.date}</time>
                    </div>
                    <p>{post.excerpt}</p>
                    <Link to={`/posts${post.fields.slug}`}>
                      Continue reading
                    </Link>
                  </div>
                );
              })}
          </section>
        </Container>
      </PostsWrapper>
    </>
  );
};

export default Posts;

export const pageQuery = graphql`
  query blogPosts {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      filter: { fields: { collection: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          fields {
            collection
            slug
          }
          frontmatter {
            title
            date(formatString: "Do MMMM YYYY")
          }
        }
      }
    }
  }
`;
